import { Box, Button, Flex, Select, Text, TextInput } from "@mantine/core";
import React, { Fragment, useMemo, useState } from "react";
import PaginatedTable from "../../components/paginated-table/PaginatedTable";
import { TTableColumns } from "../../Types/table.interface";
import { CONSTANTS, COURSE_NAME } from "../../constants/index.constant";
import { useDebouncedState, useDisclosure } from "@mantine/hooks";
import { TABLE_COLUMN } from "../../constants/tables";
import { useGetRegisteredStudents } from "../../hooks/transaction/query/useGetRegisteredStudents.query";

const Transaction = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useDebouncedState("", 300);
  const [name, setCourseName] = useState(COURSE_NAME[0]);

  const { data, isLoading, refetch } = useGetRegisteredStudents({
    page,
    search,
    name,
  });

  const { data: tableData, total } = useMemo(() => {
    if (!isLoading && data?.data) {
      const serialized =
        (data?.data as []).map((item, index) => ({
          ...(item as unknown as object),
          sno: ((page ?? 1) - 1) * CONSTANTS.PAGE_LIMIT + (index + 1),
        })) ?? [];
      return {
        data: serialized,
        total: data.extraData,
      };
    }
    return { data: [], total: 0 };
  }, [data, isLoading]);

  return (
    <Fragment>
      <Box pr={24}>
        <Flex justify={"space-between"} align={"center"}>
          <Text fw={600} fz={"h3"} my={24}>
            Student Details who purchased the course
          </Text>
        </Flex>
        <Flex justify={"space-between"} align={"center"} gap={20}>
          <Select
            mb={12}
            label={"Select Course Name"}
            placeholder="Eg. Advanced Valuation and Financial Modelling"
            data={COURSE_NAME}
            defaultValue={COURSE_NAME[0]}
            onChange={(e: any) => setCourseName(e)}
          />
          <TextInput
            onChange={(value) => setSearch(value.target.value)}
            placeholder="Search student"
            styles={{ root: { width: 218 } }}
          />
        </Flex>
        <Box>
          <PaginatedTable
            columns={
              TABLE_COLUMN.registeredStudentsColumn as TTableColumns<unknown>[]
            }
            data={tableData}
            isLoading={isLoading}
            keyExtractor={(e) => {
              const key = e as { _id: string };
              return key._id;
            }}
            paginationProps={{
              page,
              setPage,
              totalDocuments: Number(total),
              pageLimit: CONSTANTS.PAGE_LIMIT,
            }}
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export default Transaction;
