import alumniUrls from "./alumni.urls";
import { courseReviewApis } from "./courseReview.urls";
import { coursesApi } from "./courses";
import { faqApi } from "./faq";
import { joyResultApi } from "./joy-result";
import { learningModuleUrl } from "./learning-module.urls";
import { overallHomePageApi } from "./overall-home-page";
import students from "./students";

export const prefix = "/admin";
export default {
  LOGIN: "/admin-login",
  GET_NEWSLETTER: prefix + "/get-newsletters",
  GET_CONTACT: prefix + "/get-contacts",
  alumniUrls,
  GET_BLOGS: prefix + "/get-paginated-blogs",
  GET_SINGLE_BLOG: prefix + "/get-single-blog",
  CREATE_BLOG: prefix + "/create-blog",
  EDIT_BLOG: prefix + "/edit-blog",
  FETCH_CATEGORIES: prefix + "/get-all-categories",
  DELETE_BLOGS: prefix + "/delete-blog",
  CREATE_YOUTUBE_PLAYLIST: prefix + "/create-youtube-playlist",
  YOUTUBE_PLAYLIST_UPDATE: prefix + "/update-youtube-playlist",
  YOUTUBE_PLAYLIST_GET_ALL: prefix + "/get-youtube-playlist",
  YOUTUBE_PLAYLIST_DELETE: prefix + "/delete-youtube-playlist",
  joyResultApi,
  LEARNING_MODULE: learningModuleUrl,
  courseReviewApis,
  coursesApi,
  faqApi,
  overallHomePageApi,
  students,
  GET_REGISTERED_STUDENTS: prefix + "/get-registered-students",
};
