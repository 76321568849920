import { useMutation } from "@tanstack/react-query";
import { request } from "../../services/axios.service";
import apiurls from "../apiurls";

interface Props {
  _id?: string;
  course: string;
  downloadBrochure?: string;
  demoVideo?: string;
  modules: { title: string; lists: { list: string; bold?: boolean }[] }[];
}

const update = async (data: Props) => {
  const res: TServerResponse = await request({
    url: apiurls.LEARNING_MODULE.UPDATE_LEARNING_MODULE,
    method: "POST",
    data,
  });
  return res;
};

export const useUpdateLearningModuleMutation = () => {
  return useMutation({ mutationFn: update });
};
