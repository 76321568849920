import moment from "moment";
import {
  IContactTable,
  TSubjects,
  TTableColumns,
} from "../../Types/table.interface";
import ContactFile from "../../components/ContactFile";

export const contactTable: TTableColumns<IContactTable>[] = [
  {
    key: "sno",
    label: "S No",
  },
  {
    key: "name",
    label: "Name",
    renderCell: (value) => value.firstName + " " + value.lastName,
  },
  {
    key: "email",
    label: "E-Mail",
    renderCell: (value) => value.email,
  },
  {
    key: "phone",
    label: "Phone Number",
    renderCell: (value) => value.phone,
  },
  {
    key: "message",
    label: "Message",
    renderCell: (value) => value.message,
  },
  {
    key: "emailSubject",
    label: "Email Subject",
    renderCell: (value) => subject(value.emailSubject),
  },
  {
    key: "file",
    label: "File",
    renderCell: (value) => <ContactFile value={value} />,
  },
  {
    key: "date",
    label: "Date",
    renderCell: (value) =>
      moment(value.createdAt).format("DD-MMM-YYYY - hh:mm:ss A"),
  },
];

const subject = (value: TSubjects) => {
  switch (value) {
    case "courseQuery":
      return "Course Query";
    case "forHiring":
      return "For Hiring";
    case "inviteUs":
      return "Invite as a speaker";
    case "others":
      return "Others";
    case "paymentIssue":
      return "Payment Issue";
    default:
      return "N/A";
  }
};
