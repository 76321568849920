import { TTableColumns } from "../../Types/table.interface";
import { IFaq } from "../../Types/faq.interface";
export const faqColumn: TTableColumns<IFaq>[] = [
  {
    key: "sno",
    label: "S. No.",
    minWidth: 100,
  },
  {
    key: "name",
    label: "Name",
    minWidth: 250,
  },
  {
    key: "heading",
    label: "Heading",
    minWidth: 250,
  },
];
