import { Box, Flex, Select, Text } from "@mantine/core";
import React, { useState } from "react";
import ModuleForm from "./components/ModuleForm";

const COURSE_NAME = [
  "Advanced Valuation and Financial Modelling",
  "CFA Level 1 for students",
  "Equity Research Cohort",
  "Linkedin Mentoring Program",
];

const LearningModule: React.FC = () => {
  const [course, setCourse] = useState(
    "Advanced Valuation and Financial Modelling"
  );
  return (
    <Box pr={24}>
      <Flex justify={"space-between"} align={"center"}>
        <Text fw={600} fz={"h3"} my={24}>
          Learning Module {course && <Text>{`(${course})`}</Text>}
        </Text>
        <Select
          placeholder="Please Select Course"
          searchable
          data={COURSE_NAME}
          value={course}
          onChange={(e) => {
            if (e) setCourse(e);
          }}
        />
      </Flex>
      {course ? (
        <ModuleForm key={course} course={course} />
      ) : (
        <Box mt={40}>
          <Text size="lg" c="dimmed" style={{ textAlign: "center" }}>
            Please select a course to view the learning module in the top-right
            corner.
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default LearningModule;
