export const CONSTANTS = {
  PAGE_LIMIT: 10,
};

export const COURSE_NAME = [
  "Advanced Valuation and Financial Modeling",
  "CFA Level 1 for students",
  "Equity Research Cohort",
  "Linkedin Mentoring Program",
];
