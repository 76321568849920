import {
  ActionIcon,
  Box,
  Button,
  SimpleGrid,
  Text,
  TextInput,
} from "@mantine/core";
import React, { memo, useEffect } from "react";
import ModuleField from "./ModuleField";
import { useForm } from "@mantine/form";
import { learningModuleValues } from "./learningModule.initialvalue";
import { IconPlus } from "@tabler/icons-react";
import { randomId } from "@mantine/hooks";
import classes from "./moduleForm.module.css";
import { learningModuleResolver } from "./learningModule.resolver";
import { useUpdateLearningModuleMutation } from "../../../hooks/learning-module/useUpdateLearningModule.mutation";
import { showNotification } from "@mantine/notifications";
import { useGetLearningModuleByCourseQuery } from "../../../hooks/learning-module/useGetLearningModuleByCourse.query";

interface Props {
  course: string;
}

interface ILearningModuleData {
  _id?: string;
  course: string;
  downloadBrochure?: string;
  demoVideo?: string;
  modules: { title: string; lists: { list: string; bold?: boolean }[] }[];
}

const ModuleForm: React.FC<Props> = ({ course }) => {
  const fromHandler = useForm({
    initialValues: learningModuleValues,
    validate: learningModuleResolver,
  });
  const { onSubmit, getValues, insertListItem, getInputProps, setValues } =
    fromHandler;

  const { data } = useGetLearningModuleByCourseQuery({ course: course });

  useEffect(() => {
    if (data?.status === "success" && data?.data) {
      const value = data.data as ILearningModuleData;
      setValues({
        _id: data.data._id,
        modules: value.modules.map((e) => ({
          title: e.title,
          key: randomId(),
          lists: e.lists.map((li) => ({
            list: li.list,
            bold: li.bold,
            key: randomId(),
          })),
        })),
        demoVideo: value.demoVideo ?? "",
        downloadBrochure: value.downloadBrochure ?? "",
      });
    }
  }, [data?.data]);

  const { mutateAsync, isPending } = useUpdateLearningModuleMutation();

  const handleSubmit = async (e: typeof learningModuleValues) => {
    const res = await mutateAsync({
      _id: data?.data._id,
      course,
      modules: e.modules.map((e) => ({
        title: e.title,
        lists: e.lists.map((li) => ({ list: li.list, bold: li.bold })),
      })),
      demoVideo: e.demoVideo,
      downloadBrochure: e.downloadBrochure,
    });

    if (res.status === "success") {
      showNotification({ message: res.message, color: "green" });
    }
  };

  return (
    <div>
      <form onSubmit={onSubmit(handleSubmit)}>
        <SimpleGrid cols={2}>
          <TextInput
            label="Download Brochure"
            placeholder="Enter Brochure link"
            {...getInputProps("downloadBrochure")}
          />
          <TextInput
            label="Demo Video"
            placeholder="Enter demo video link"
            {...getInputProps("demoVideo")}
          />
        </SimpleGrid>
        <Box mt={24}>
          <Text size="xl" mb={12}>
            Modules
          </Text>
          {getValues().modules.map((e, i, all) => {
            return (
              <ModuleField
                key={e.key}
                fromHandler={fromHandler}
                index={i}
                showTrashIcon={all.length > 1}
              />
            );
          })}
        </Box>

        <ActionIcon
          size={"xl"}
          radius={50}
          onClick={() => {
            insertListItem("modules", {
              title: "",
              lists: [{ title: "" }],
              key: randomId(),
            });
          }}
          className={classes.actionBtn}
        >
          <IconPlus style={{ width: "70%", height: "70%" }} />
        </ActionIcon>
        <Button type="submit" fullWidth mb={70} loading={isPending}>
          Submit
        </Button>
      </form>
    </div>
  );
};

export default memo(ModuleForm);
