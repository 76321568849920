import * as Yup from "yup";
import { ICourseReview } from "../../Types/courseReview.interface";

const initialValues: ICourseReview = {
  courseName: "",
  name: "",
  description: "",
  imageUrl: "",
  linkedinUrl: "",
  designation: "Marketing Director at XYZ Corp",
};
const validationSchema = Yup.object().shape({
  courseName: Yup.string().required("Course Name is required"),
  name: Yup.string().required("Name is required"),
  description: Yup.string().required("Descripction is required"),
  // linkedinUrl: Yup.string()
  //   .required("LinkedIn URL is required")
  //   .url("Please enter a valid LinkedIn URL"),
  // imageUrl: Yup.string()
  //   .required("Linkedin Profile is required")
  //   .url("Please enter a valid linkedin profile URL"),
  designation: Yup.string().required("Designation is required"),
});
export const course_review_form = {
  initialValues,
  validationSchema,
};
