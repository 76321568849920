import React, { memo, useState } from "react";
import classes from "./Sidebar.module.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ISideBarMenu, SIDEBAR_MENU } from "./SideBarMenu";
import { IconCaretDownFilled, IconCaretUpFilled } from "@tabler/icons-react";

const Sidebar = () => {
  const location = useLocation(); // Get current URL
  const navigate = useNavigate();

  const [openMenu, setOpenMenu] = useState<string | null>(null);

  const toggleSubMenu = (label: string) => {
    setOpenMenu((prev) => (prev === label ? null : label));
  };

  const renderMenu = (menu: ISideBarMenu[]) =>
    menu.map((item) => (
      <div key={item.label} className={classes.menuItem}>
        {/* Main Menu Item */}
        <div
          className={`${classes.link} ${
            item.subMenu ? classes.hasSubMenu : ""
          }`}
          data-active={location.pathname === item.link ? "true" : undefined}
          onClick={(event) => {
            event.preventDefault();
            if (item.subMenu) {
              toggleSubMenu(item.label);
            } else {
              navigate(item.link, { replace: false });
            }
          }}
        >
          {item.icon}
          <span>{item.label}</span>
          {item.subMenu &&
            (openMenu === item.label ? (
              <IconCaretUpFilled />
            ) : (
              <IconCaretDownFilled />
            ))}
        </div>

        {/* Submenu */}
        {item.subMenu && openMenu === item.label && (
          <div className={classes.subMenu}>
            {item.subMenu.map((subItem) => (
              <Link
                key={subItem.label}
                className={classes.link}
                to={subItem.link}
                data-active={
                  location.pathname === subItem.link ? "true" : undefined
                }
                onClick={(event) => {
                  event.preventDefault();
                  navigate(subItem.link, { replace: false });
                }}
              >
                {subItem.icon}
                <span>{subItem.label}</span>
              </Link>
            ))}
          </div>
        )}
      </div>
    ));

  return (
    <nav className={classes.navbar}>
      <div className={classes.navbarMain}>{renderMenu(SIDEBAR_MENU)}</div>
    </nav>
  );
};

export default memo(Sidebar);
