import { Text } from "@mantine/core";
import { modals } from "@mantine/modals";

interface Props {
  title: string;
  msg?: string;
  labels?: { confirm: string; cancel: string };
  onConfirm?: () => void;
  children?: JSX.Element;
}

export const ConfirmationAlert = (data: Props) =>
  modals.openConfirmModal({
    title: data.title,
    children: <Text size="sm">{data.msg}</Text>,
    labels: data.labels,
    onConfirm: data.onConfirm,
  });
