import { useQuery } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import apiurls from "../../apiurls";

const get = async (data: { page: number; search: string,courseName:string }) => {
  const res: TServerResponse = await request({
    url: apiurls.courseReviewApis.GET_ALL,
    method: "GET",
    params: {
      page: data.page,
      search: data.search,
      courseName:data.courseName
    },
  });
  return res;
};

export const useGetAllCourseReview = (data: { page: number; search: string,courseName:string }) => {
  return useQuery({
    queryKey: ["get-course-reviews", data.page, data.search,data.courseName],
    queryFn: () => get(data),
  });
};
