import React, { memo } from "react";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import { ROUTES } from "../../../enum/routes";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import { Outlet } from "react-router-dom";

const Layout = () => {
  const isAuthenticated = useIsAuthenticated();
  if (!isAuthenticated) {
    window.location.replace(`${ROUTES.ADMIN_LOGIN}`);
  }
  return (
    <div>
        <Header />
        <Sidebar />
        <div style={{ marginLeft: "285px", paddingTop: "5rem" }}>
          <Outlet />
        </div>
      </div>
  );
};

export default memo(Layout);
