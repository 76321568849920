import { TTableColumns } from "../../Types/table.interface";
import { IRegisterd } from "../../Types/registedStudents.interface";
import moment from "moment";
export const registeredStudentsColumn: TTableColumns<IRegisterd>[] = [
  {
    key: "sno",
    label: "S. No.",
  },
  {
    key: "name",
    label: "Name",
    renderCell: (value) => value.firstName + " " + value.lastName,
  },
  {
    key: "email",
    label: "Email",
  },
  {
    key: "mobile",
    label: "Mobile",
  },
  {
    key: "courseName",
    label: "Course",
  },
  {
    key: "packageName",
    label: "Package Name",
  },
  {
    key: "price",
    label: "Package Name",
  },
  {
    key: "purchasedDate",
    label: "Purchased At",
    renderCell: (value) =>
      moment(value.purchasedDate).format("DD-MMM-YYYY - hh:mm:ss A"),
  },
  {
    key: "pdfPath",
    label: "Invoice",
    renderCell: (value) => (
      <a
        href={value.pdfPath as string}
        target="_blank"
        rel="noopener noreferrer"
      >
        Link
      </a>
    ),
  },
];
