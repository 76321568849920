export const ROUTES = {
  ADMIN_LOGIN: "/login",
  ADMINDASHBOARD: "/",
  STUDENTS: "/students",
  NEWSLETTER: "/newsletters",
  CONTACT_US: "/contact-us",
  ALUMNIS: "/alumnis",
  BLOGS: "/blogs",
  ADD_BLOG: "add-blog",
  EDIT_BLOG: "edit-blog/:id",
  YOUTUBE_PLAYLIST: "/youtube-playlist",
  JOY_RESULT: "/joy-result",
  LEARNING_MODULE: "/learning-module",
  COURSE_REVIEWS: "/course-reviews",
  COURSES: "/courses",
  ADD_COURSE: "add-course",
  EDIT_COURSE: "edit-course/:id",
  FAQ: "/faq",
  LANDING_PAGE: "/home-page",
  REGISTERED_STUDENTS: "/transactions",
};
