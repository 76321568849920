import { Box, Button,  Image } from "@mantine/core";
import React, { memo } from "react";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import classes from "./index.module.css";
import { ROUTES } from "../../../enum/routes";
import { IMAGES } from "../../../assets/images";

const Header = () => {
  const signOut = useSignOut();
  const navigate = useNavigate();
  return (
    <Box className={classes.nav}>
      <Box className={classes.imageContainer}>
        <Image
          src={IMAGES.navbar_logo}
          alt="navbar_logo"
          className={classes.img}
        />
      </Box>
      <Button
        color="green"
        onClick={() => {
          signOut();
          navigate(`${ROUTES.ADMIN_LOGIN}`);
        }}
      >
        Logout
      </Button>
    </Box>
  );
};

export default memo(Header);
